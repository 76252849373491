body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.App {
    min-height: calc(100vh - 3.75rem);
}

.row {
    justify-content: center;
    width: 100%;
}

.row-text {
    justify-content: center;
    width: 75%;
    margin-left: 30px;
}

.home-jumbotron {
    background-image: url("static/earth-bulb.jpg");
    background-position: center;
    background-size: cover;
    height: 80vh;
}

.home-jumbotron-summary {
    position: absolute;
    background-color: rgba(191, 207, 217, 0.4);
    color: whitesmoke;
    margin-left: 50px;
    margin-top: 50px;
    max-width: 350px;
    border-radius: 20px;
    padding: 20px;
    font-size: 1.05rem;
}


@media screen and (max-width: 700px) {
    .home-jumbotron-summary {
        background-color: rgba(191, 207, 217, 0.9);
        margin-left: 10px;
    }
}

@media screen and (max-width: 400px) {
    .home-jumbotron-summary {
        margin-left: 0px;
    }
}



.home-jumbotron-summary span {
    font-size: 1.4rem;
}

.game-summary {
    line-height: 1.5rem;
    color: whitesmoke;
    text-align: justify;
    font-size: 1.1rem;
}

div.need-icon-row.row {
    margin-left: 0;
    margin-bottom: 20px;
    margin-top: 10px;
}

.need-icon-row img {
    width: 35px;
}

h3,
h4,
h5,
h6 {
    margin-bottom: 60px;
    color: rgb(87, 102, 120);
}

h6 {
    font-size: 1.15rem !important;
}

.row.target-group {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
}

.row.outer {
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 0px;
}

.target-group-description {
    text-align: justify;
}

.target-image-row.row {
    margin-left: 5px;
}

.target-image-row.row img {
    width: 250px;
}

.check-icon.col {
    max-width: 35px;
}

.check-icon.col img {
    width: 22px;
}

.why-image-col img {
    width: 50%;
}

.how-to-image-col img {
    width: 65%;
}

.list-text {
    text-align: left;
    margin-top: 30px;
}

.list-item-col {
    margin-bottom: 10px;
}

.opinion-carousel {
    margin-top: 80px;
    margin-bottom: 60px;
    background-image: url("static/lake.jpg");
    background-attachment: fixed;
    background-position-y: center;
}

.opinion-carousel-overlay {
    background-color: rgb(74, 70, 55, 0.4);
}

.opinion-card {
    width: 20rem;
    margin: 20px;
    height: 15rem;
}

.questionmark-img {
    width: 20px;
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.opinion-text-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.opinion-name {
    font-style: italic;
    color: rgb(87, 102, 120);
}

.about {
    text-align: justify;
    padding: 20px;
}

.form-label1 {
    text-align: left;
}

a.nav-link-active {
    text-decoration: overline;
}

div.play-jumbotron.jumbotron {
    background-color: rgb(123, 171, 189);
}

.game-summary span {
    font-size: 2rem;
}

.downloads-text-top {
    margin-top: 20px;
    margin-bottom: 20px;
}

.download-card-row {
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
}

.download-card-col {
    max-width: 320px !important;
    background-color: lightgrey;
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
}

div.download-card-top-faci {
    background-color: rgb(9, 79, 106) !important;
    height: 200px;
    min-width: 100%;
    display: grid;
}

.download-card-top-icon-faci {
    background-color: rgb(9, 79, 106) !important;
    height: 200px;
    min-width: 100%;
}

.download-card-top-icon-player {
    background-color: rgb(94, 124, 118) !important;
    height: 200px;
    min-width: 100%;
}

.download-icon-top {
    height: 70%;
    margin-top: 30px;
}

img.download-card-software-img {
    width: 85%;
    align-self: center;
    justify-self: center;
}

div.download-card-text-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px;
    padding-bottom: 20px;
}

.download-card-text {
    text-align: left;
}

.download-card-button {
    margin-top: auto;
}

.download-card-button button {
    width: 100px;
}

.download-card-title-box {
    height: 90px;
    border-color:rgb(87, 102, 120);
    border-width: 0 0 1px 0;
    border-style:solid;
    margin-bottom: 20px;
}

button.btn.btn-warning > a:hover {
    text-decoration: none;
}

button.btn.btn-warning > a {
    color: black;
}

.about span {
    font-weight: bold;
}

.about-card-group {
    margin: 20px;
}

div.about-card-image-div {
    margin-top: 20px;
}

div.about-card-image-div img {
    width: 150px;
}

p.about-text {
    text-align: justify;
}

.about-contact-div {
    background-image: url("static/poppies.jpg");
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: grid;
    padding: 20px;
}

.about-contact-inner-div {
    align-self: center;
    justify-self: center;
    background-color: whitesmoke;
    padding: 10px;
}

form {
    margin: 20px !important;
}

label {
    float: left;
}

.text-muted {
    float: left;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
}

.support-div {
    text-align: center;
    align-self: center;
    color: rgb(41, 44, 121);
    margin-bottom: 0.5rem;
}

.logo-div {
    align-self: flex-end;
}

footer {
    background-color: rgba(20, 21, 54);
    color: silver;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    height: 80px;
}
